.contact__us {
  min-height: 90vh;
  padding: 20px;
  max-width: 1000px;
  margin: 50px auto;
  h2 {
    margin-bottom: 20px;
    text-align: center;
    color: dodgerblue;
    font-size: 2rem;
  }
  form {
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    h3 {
      color: dodgerblue;
      margin-bottom: 10px;
    }
    label {
      color: purple;
      font-weight: bold;
    }
    input {
      margin-bottom: 15px;
      padding: 7px;
      border-radius: 5px;
      border: 1px solid lightgray;
      outline: none;
    }
    input:focus {
      border-color: dodgerblue;
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: 100px;
      max-height: 250px;
      border-radius: 10px;
      outline: none;
      padding: 5px 10px;
    }
    textarea:focus {
      border: 1px solid dodgerblue;
    }
    .form__Buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
