.about__us {
  padding: 10px;
  max-width: 1400px;
  margin: 10px auto;
  min-height: 85vh;
  & > * {
    padding: 30px 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 15px;
    text-align: center;
    margin: 25px 0px;
  }
  .first__row {
    h3 {
      color: dodgerblue;
    }
  }
  & h2 {
    color: dodgerblue;
    font-size: 2rem;
  }
  & h3 {
    font-size: 1.5rem;
  }
  & p {
    margin-bottom: 15px;
    font-size: 19px;
  }
}
