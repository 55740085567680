.not__found {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  text-align: center;
  min-height: 70vh;
}
.not__foundHeading {
  margin-bottom: 40px;
  color: purple;
  font-size: 3rem !important;
}
.not__found > p {
  color: dodgerblue;
  font-weight: bold;
  font-size: 1.8em;
}
