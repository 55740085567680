/*===== NAV =====*/
.left-navbar {
  position: fixed;
  top: 0;
  left: -15px !important;
  width: 0px;
  height: 100vh;
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  background: linear-gradient(to top, #a051e0, #5e2fd6);
}

.nav {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.nav__list {
  padding-top: 5rem;
}
.nav__logo,
.nav__link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.1rem 0 0.1rem 2.5rem;
}

.nav__logo {
  margin-bottom: 2rem;
}

.nav__logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav__logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav__link {
  position: relative;
  color: white;
  margin-bottom: 0.8rem;
  transition: 0.3s;
  font-size: 1.1rem;
}

.nav__link:hover {
  color: var(--white-color);
}
.sidebar__submenu {
  font-size: 0.9rem;
  padding: 0px 0px 0px 4.3rem;
}

.nav__icon {
  font-size: 1.25rem;
}

/*Show navbar movil*/
.show {
  left: 0;
}

/*Add padding body movil*/
.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

/*Active links*/
.active {
  font-weight: bold;
  color:yellow
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

/* ===== MEDIA QUERIES=====*/
/* @media screen and (min-width: 768px) { */
.left-navbar {
  left: 0;
  padding: 1rem 1rem 0 0;
}
/*Show navbar desktop*/
.show {
  width: calc(var(--nav-width) + 150px);
}
/* } */
