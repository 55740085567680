.renew__license {
    max-width:500px;
    display: flex;
    flex-direction: column;
    margin: 30vh auto;
    align-items: center;
    h3 {
        color:dodgerblue;
        margin-bottom:20px;
        font-size: 1.3rem;
    }
    button {
        max-width:150px;
        background: linear-gradient(to right, var(--first-color), dodgerblue);
        color: #ffffff;
        font-weight: bold;
        padding: 8px 25px;
    }
}