.emails__listing {
  list-style-type: none;
  margin-left: 5px;
  outline: 1px solid lightgray;
  .not__read {
    font-weight: bold;
  }
  & li {
    color: unset;
    display: flex;
    align-items: center;
    border-bottom: 0.05px solid lightgray;
    border-radius: 5px;
    padding: 7px;
    .checkbox {
      flex: 0.05;
    }
    .email__overview {
      display: flex;
      cursor: pointer;
      flex: 0.85;
      * {
        margin-right: 5px;
      }
      .sender {
        flex: 0.25;
      }

      .subject {
        flex: 0.5;
      }
      .time {
        flex: 0.2;
      }
    }
    .actions {
      flex: 0.1;
    }
  }
}
