.overview {
  display: flex;
  background-color: #fafafa;
  padding: 10px 5px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.overview__left {
  flex: 0.6;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  border-radius: 10px;
  padding: 15px;
  margin-right: 10px;
}
.overview__left > h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: dodgerblue;
}
.overview__left > h2 {
  margin-bottom: 25px;
  font-size: 3rem;
  color:purple;
}
.overview__left > p {
  margin-bottom: 25px;
  font-size: 1.1rem;
}
.overview__left > button {
  max-width: 150px;
  background: linear-gradient(to right, var(--first-color), dodgerblue);
  color: white;
  padding: 10px 15px;
}
.overview__right {
  flex: 0.4;
}
.overview__right > img {
  max-height: 100%;
  max-width: 100%;
  width: fit-content;
  border-radius: 10px;
  /* to align image to the right use the two styles below */
  display: block;
  margin-left: auto;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 768px) {
  .overview {
    flex-direction: column;
  }
  .overview__left {
    margin-bottom: 20px;
  }
}
