/*===== HEADER =====*/
.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 5px 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  z-index: 1;

  & button {
    background-color: transparent;
    color: dodgerblue;
    font-weight: bold;
  }
}
.header__left {
  flex: 0.3;
  margin-right: 10px;
  display: flex;
  align-items: center;
  i {
    font-size:1.5rem;
    cursor:pointer;
    margin-right:15px;
  }
  i:hover {
    opacity:0.6;
  }
}
.header__left > h1 {
  display: flex;
  align-items: center;
}
.header__left > h1 > img {
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.header__left > h1 > a {
  font-size: 1.7rem;
  color: dodgerblue;
  font-family: Verdana, Geneva, sans-serif;
}
.header__left > h1 > a:hover {
  opacity: 0.6;
}
.header__center {
  flex: 0.7;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  justify-items: flex-end;
}
.header__center > .dropdown {
  position: relative;
  display: inline-block;
}
.header__center .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}
.header__center .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}
.dropbtn {
  font-size: 15px;
}
.login__button {
  background: linear-gradient(to right, var(--first-color), dodgerblue);
  color: #ffffff !important;
}
.header__center .dropdown-content a:hover {
  background-color: #ddd;
}
.header__center .dropdown:hover .dropdown-content {
  display: block;
}
.authenticated {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.authenticated > .MuiAvatar-root {
  width: 27px;
  height: 27px;
  margin-right: 5px;
}
.authenticated > p {
  font-size: 17px;
}

.hideBtn {
  display: none;
}
/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px) {
  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 2rem;
  }
  .mobile__menu {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .header__left > h1 > a {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  .header__left {
    flex: 0.9;
  }
  .header__center {
    flex: 0.1;
  }
  .desktop__menu {
    display: none;
  }
  .mobile__menu {
    justify-content: end;
    justify-self: flex-end;
  }
  .mobile__menu > i {
    z-index: 1;
    font-size: 30px;
    color: dodgerblue;
  }
  .desktop__menu {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    position: fixed;
    right: -10px;
    top: 0;
    height: 100vh;
    width: 0;
    justify-content: flex-start;
    padding-top: 50px;
    transition: 0.5s;
  }
  .desktop__menu > * {
    padding-bottom: 10px;
  }
  .toggle__mobileMenu {
    width: 200px;
  }
  .hideBtn {
    display: none;
  }
}
