.web__apps {
  padding: 15px;
  min-height: 90vh;
  .first__row {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 15px;
    padding: 20px;
    img {
      display: block;
      margin: auto;
      border-radius: 15px;
      max-width: 100%;
    }
    h2 {
      margin: auto 20px;
      font-size: 2.8rem;
      text-align: center;
      color: dodgerblue;
    }
  }
  .second__row {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 15px;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    text-align: center;
    h2 {
      margin-bottom: 5px;
      color: dodgerblue;
      font-size: 27px;
    }
    h3 {
    }
    ul {
      list-style-type: none;
      li {
        margin-bottom: 15px;
        padding-left: 5px;
      }
    }
  }
}
@media screen and(max-width:768px) {
  .web__apps {
    .first__row {
      flex-direction: column;
      h2 {
        font-size: 1.6rem;
        margin-top: 15px;
      }
    }
  }
}
