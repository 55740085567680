.game__fans {
  padding: 15px;
  min-height: 90vh;
  max-width: 1600px;
  margin: auto;
  & > * {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 15px;
    padding: 25px;
  }
  .first__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      flex: 0.5;
      border-radius: 20px;
      margin-right: 20px;
      max-width: 100%;
    }
    h2 {
      flex: 0.5;
      text-align: center;
      color: dodgerblue;
      font-size: 2.5rem;
    }
  }
  .second__row {
    margin-top: 25px;
    text-align: center;
    h2 {
      color: dodgerblue;
    }
    h3 {
      margin-top: 10px;
      font-size: 1.4rem;
    }
    ul {
      list-style-type: none;
    }
  }
}

@media screen and(max-width:768px) {
  .game__fans {
    .first__row {
      flex-direction: column;
      h2 {
        font-size: 1.4rem;
        margin-top: 15px;
      }
    }
    .second__row {
      h2 {
        font-size: 1.4rem;
      }
      h3 {
        font-size: 1.2rem;
      }
    }
  }
}
