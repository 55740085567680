.profile {
  background-color: #f1f1f1;
  padding: 15px 20px;
  max-width: 1000px;
  margin: 20vh auto;
  border-radius: 10px;
}
.profile > h2 {
  text-align: center;
  font-size: 1.4rem;
  color: dodgerblue;
  text-decoration: underline;
}
.profile__row {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 10px auto;
}
.profile__row > span {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 45%;
  margin-right: 10px;
}
.profile__row > span > label,
.profile__rowSingleItem > label {
  color: purple;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 10px;
  padding-left: 5px;
}
.profile__row > span > input {
  height: 30px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding-left: 10px;
  outline: none;
}

.profile__rowSingleItem {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 10px auto;
}
.profile__rowSingleItem > textarea {
  padding: 10px;
  max-width: 100%;
  min-width: 100%;
  max-height: 100px;
  min-height: 100px;
  border: 2px solid #ccc;
  border-radius: 10px;
  outline: none;
}
.profile__row > span > input:focus,
.profile__rowSingleItem > textarea:focus {
  border: 2px solid dodgerblue;
}
.profile__Buttons {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
}
.profile__Buttons > button {
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}
.profile__Buttons .update {
  background-color: dodgerblue;
}
.profile__Buttons .change__password {
  background-color: #4774c9;
}
