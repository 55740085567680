/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3.6rem;
  --nav-width: 68px;
  /*===== Colors =====*/
  --first-color: #4723d9;
  --theme-color-1:purple;
  --theme-color-2:dodgerblue;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;

  /*===== Font and typography =====*/
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 0.9rem;

  /*===== z index =====*/
  --z-fixed: 100;
}
/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.button,
button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 3px 15px 5px 15px;
  border-radius: 15px;
}
.button__sp {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 5px 15px !important;
}
.button:hover,
button:hover,
a:hover, .button__sp:hover {
  opacity: 0.6;
}
a {
  text-decoration: none;
  color: #ffffff;
}
.active__link {
  color: purple !important;
}
.add__button {
  background: linear-gradient(to right, var(--first-color), dodgerblue);
  color: #ffffff;
  margin-top: 5px;
}

.white {
  color: white;
}
.dodgerblue {
  color: dodgerblue;
}
.purple {
  color: purple;
}
.green {
  color: green;
}
.red {
  color: red !important;
}

.bg-dodgerblue {
  background-color: dodgerblue;
}
.bg-green {
  background-color: green;
}
.bg-red {
  background-color: red;
}
.bd {
  font-weight: 700;
}
.ud {
  text-decoration: underline;
}
.tc {
  text-align: center;
}
.tl {
  text-align: left;
}

/* styles for dialog components */
.dialog {
  padding: 15px 20px;
}
.dialog > h3 {
  color: dodgerblue;
  text-align: center;
  text-decoration: underline;
}
.dialog textarea {
  min-width: 100%;
  width: 100%;
  max-width: 600px;
  min-height: 80px;
  max-height: 80px;
  border-radius: 15px;
  border: 2px solid lightgray;
  outline: none;
  padding: 5px 15px;
  resize: none;
}
.dialog textarea:focus {
  border: 2px solid dodgerblue;
}

/* for items spanning the entire width */
.dialog__rowSingleItem {
  display: flex;
  flex-direction: column;
  max-width: 95%;
  margin: 5px auto 10px auto;
}
.dialog__rowSingleItem > label {
  color: purple;
  font-weight: bold;
  padding: 3px 8px;
  width: 100%;
  max-width: 100%;
  margin: 2px auto;
}
.dialog__row {
  display: flex;
  margin: 10px auto;
  max-width: 95%;
}
.dialog__row > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.5;
  margin-right: 15px;
}
.dialog__row > span:nth-child(1) {
  margin-right: 20px;
}
.dialog__row label {
  color: purple;
  font-weight: bold;
  padding: 3px 8px;
}

.dialog__row input,
.dialog__row select,
.dialog__rowSingleItem input,
.dialog__rowSingleItem select {
  padding: 7px 10px;
  border-radius: 10px;
  border: 2px solid lightgray;
  outline: none;
}
.dialog__row > input:focus,
.dialog__rowSingleItem > input:focus,
.dialog__row > select:focus {
  border: 2px solid dodgerblue;
}

.form__Buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 15px;
}

.form__Buttons > button {
  padding: 5px 20px 7px 20px;
  margin: 0px 20px;
  max-width: 150px;
  color: white;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
.form__Buttons > button:nth-child(1) {
  background: linear-gradient(to bottom, #cd03ff, #f00030);
  justify-self: start;
}
.form__Buttons > button:nth-child(2) {
  background: linear-gradient(to right, var(--first-color), dodgerblue);
  justify-self: end;
}
.extra__formButtons {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 15px auto;
  color: dodgerblue;
  font-weight: bold;
}
/* COMMON TABLE STYLING */
.table__parent {
  max-width: 100%;
  margin: 10vh auto 20px auto;
}
.table__parentHeader {
  text-align: center;
  h3 {
    color: dodgerblue;
    text-decoration: underline;
    margin-top: 10px;
  }
  button {
    background: linear-gradient(to right, var(--first-color), dodgerblue);
    color: #ffffff;
    margin-top: 5px;
    font-weight: bold;
  }
}
.table__parentSubHeader {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin: 8px auto 5px auto;
  justify-content: space-around;
  align-items: flex-end;
}
.table__parentSubHeader > span {
  display: flex;
  flex-direction: column;
}
.table__parentSubHeader > * {
  margin-bottom: 8px;
  margin-right: 8px;
}
.table__listing {
  width: 100%;
  max-width: 1200px;
  margin: 5px auto 25px auto;
}
.table__listingHeader {
  color: white;
  background: linear-gradient(to bottom, var(--first-color), dodgerblue);
}
.table__listingHeader > th {
  text-align: left;
  padding: 6px;
}
.table__listingItem {
  background-color: #f1f1f1;
}
.table__listingItem > td {
  padding: 5px;
  text-align: left;
}
/* showing tables totals */
.table__listingFooter > th {
  padding: 5px 10px;
  text-align: left;
  background: linear-gradient(to right, #ebeded, #d7dbda);
  color: dodgerblue;
}
/* end of dialog components */

#pageSubmitting,
#formSubmitting {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed !important;
}

/* error styling */
.response__message {
  font-weight: bold;
  text-align: center;
  height: fit-content;
  font-size: 1rem;
  margin-top: 5px auto;
  padding: 4px;
  width: 90%;
  position: absolute;
  max-width: 100%;
}
/* we set the background for each item since we only want it to be visible when the error is being shown */
.error {
  color: red;
  background-color: #f1f1f1;
}
.success {
  color: #02ab4b;
  background-color: #f1f1f1;
}
.not__available {
  color: red !important;
  text-align: center !important;
}
.remove__item:hover {
  cursor: pointer;
  text-decoration: line-through;
  color: red;
}


/* report statements styling */
.report__statement {
  width: 100%;
  thead {
    tr {
      th,
      td {
        border-top: 1px solid lightgray;
        border-right: 1px solid lightgray;
        padding: 3px 5px;
        font-size: 1.2rem;
      }
      th:first-child,
      td:first-child {
        border-left: 1px solid lightgray;
      }
    }
  }
  tbody {
    tr {
      td,
      th {
        padding-left: 15px;
        text-align: left;
        border-right: 1px solid black;
      }
    }
    .parent_ledger_groups_totals {
      background-color: rgb(165, 158, 158);
      font-size: 1.1rem;
    }
    .parent_ledger_group {
      font-size: 1.1rem;
      color: purple;
      background-color: lightgray;
    }
    .ledger_group {
      th {
        padding-left: 25px;
      }
      font-size: 1rem;
    }
    .ledger {
      td {
        padding-left: 35px;
      }

      font-size: 0.95rem;
    }
    .amount {
      text-align: center;
    }
  }
}

/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    /* padding-left: calc(var(--nav-width) + 2rem); */
  }

  /*Add padding body desktop*/
  .body-pd {
    margin-left: calc(var(--nav-width));
  }
}

@media screen and (max-width: 768px) {
  .dialog__row {
    flex-direction: column;
  }
}
