.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  padding: 30px var( --nav-width);
  background: #667db6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #667db6,
    #0082c8,
    #0082c8,
    #667db6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #667db6,
    #0082c8,
    #0082c8,
    #667db6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.footer > * {
  margin-right: 25px;
}
.footer__first {
  text-align: center;
}
.footer__first > p {
  color: #e9e7e7;
  font-weight: bold;
}
.footer__logo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.footer__logo > img {
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.footer__logo > h1 {
  font-family: "Arial Black", Gadget, sans-serif;
  font-size: 1.6rem;
  color: #e9e7e7;
}
.footer h2 {
  color: #d3d4d4;
  margin-bottom: 10px;
}
.footer ul {
  list-style-type: none;
  padding-left: 6px;
}
.footer ul > li {
  margin-bottom: 5px;
  font-weight: bold;
  color: #e9e7e7;
}
.footer ul > li > a:hover {
  opacity: 0.6;
}
.footer__fourth form {
  display: flex;
}
.footer__fourth form > input {
  padding: 5px;
  outline: none;
  border: none;
}
.footer__fourth form > button {
  all: unset;
  background-color: #ffffff;
  padding: 1px 8px;
  color: dodgerblue;
  cursor: pointer;
}
.footer__fourth form > button:hover {
  opacity: 0.6;
}
@media screen and(max-width:768px) {
  .footer {
    .footer__first {
      margin-bottom: 20px;
      h1 {
        font-size: 1.2rem;
      }
    }
    .footer__second {
      margin-bottom: 20px;
    }
    .footer__third {
      margin-bottom: 20px;
    }

    h2 {
      font-size: 1.2rem;
    }
    ul {
      li {
        font-size: 1rem;
      }
    }
  }
}
