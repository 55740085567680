.courzehub {
  min-height: 80vh;
  padding: 15px;
  max-width: 1600px;
  margin: auto;
  & > * {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 15px;
    padding: 25px;
  }
  .first__row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 2.6rem;
        color: dodgerblue;
        text-align: center;
        margin-right: 20px;
      }
      a {
        width:fit-content;
        padding:5px 10px;
        border-radius: 10px;
        font-weight: bold;
      }
    }

    
    img {
      border-radius: 20px;
    }
  }
  .second__row {
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    text-align: center;
    h2 {
      color: dodgerblue;
      font-size: 2rem;
    }
    h3 {
      margin-top: 17px;
      font-size: 1.5rem;
    }
    ul {
      list-style-type: none;
      li {
        font-size: 1.2rem;
      }
    }
  }
}
@media screen and(max-width:768px) {
  .courzehub {
    .first__row {
      flex-direction: column;
      h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
      }
      img {
        max-width: 100%;
      }
    }
    .second__row {
      h2 {
        font-size: 1.7rem;
      }
      h3 {
        font-size: 1.3rem;
      }
      ul {
        li {
          font-size: 1.1rem;
        }
      }
    }
  }
}
