.accounting {
  margin: 10vh auto;
  margin-left: calc(var(--nav-width));
  max-width: 100%;
  
}
.accounting__actions {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  button {
    background: linear-gradient(
      to right,
      var(--theme-color-1),
      var(--theme-color-2)
    );
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #ffffff;
    margin: 5px;
    font-weight: bold;
  }
}

.account__ledgersListing {
  * {
    margin: 10px;
  }
  div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
    table {
      flex: 1;
      background-color: #ececec;
      height: fit-content;
      width: 100%;
      padding: 5px 15px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      thead {
        text-align: left;
        color: dodgerblue;
        text-decoration: underline;
      }
      .show__all {
        color: green;
        font-size: 0.9rem;
        text-align: left;
        cursor: pointer;
        :hover {
          opacity: 0.6;
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .account__ledgersListing {
    div {
      flex-direction: column;
    }
  }
}
