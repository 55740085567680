.mobile__apps {
  padding: 20px;
  margin: auto;
  .first__row {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    border-radius: 20px;
    padding: 20px;
    h2 {
      flex: 0.5;
      margin: auto 5px;
      font-size: 2.8rem;
      text-align: center;
      color: dodgerblue;
    }
    img {
      flex: 0.5;
      max-width: 100%;
      border-radius: 20px;
      margin: auto;
      display: block;
    }
  }
  .second__row {
    padding: 10px 20px;
    margin: 20px auto 0px auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    max-width: 1200px;
    text-align: center;
  }
  h2 {
    margin-bottom: 5px;
    font-size: 27px;
    color: dodgerblue;
  }
  h3 {
    margin-bottom: 5px;
  }
  ul {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 15px;
  }
}
@media screen and(max-width:768px) {
  .mobile__apps {
    .first__row {
      flex-direction: column;
      h2 {
        font-size: 1.6rem;
        margin-bottom: 15px;
      }
    }
  }
}
