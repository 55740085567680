.more__info {
  margin: 10px 5px;
  background-color: #f2f2f2;
  border-radius: 15px;
}
.more__info > div {
  max-width: 1200px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
}
.more__infoLeft {
  flex: 0.5;
  padding: 5px 10px;
  height: 300px;
}
.more__infoLeft > img {
  max-height: 100%;
  border-radius: 10px;
  max-width: 100%;
}
.more__infoRight {
  flex: 0.5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.more__infoRight > h3 {
  color: dodgerblue;
  margin-bottom: 15px;
}
.more__infoRight > h2 {
  font-size: 1.7rem;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .more__info > div {
    flex-direction: column;
  }
  .more__infoLeft {
    margin-bottom: 20px;
  }
}
