.home__card {
  max-width: 270px;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home__card > h3 {
  font-size: 1rem;
}
.home__card > img {
  width: 100%;
  height: 50%;
}
.home__card > p {
  font-size: 0.9rem;
}
