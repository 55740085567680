.reports {
  text-align: center;
  h4 {
    color: purple;
    text-align: center;
    margin-top: 10px;
    text-decoration: underline;
  }
  label {
    text-align: left;
  }
}
