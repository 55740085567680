.featured {
  text-align: center;
  background-color: #fafafa;
  margin: 10px 5px 15px 5px;
  padding: 20px 5px;
  border-radius: 15px;
}
.featured > h3 {
  color: dodgerblue;
  font-size: 1.5rem;
}
.featured > h2 {
  font-size: 1.6rem;
  color:purple;
  margin:10px 0px;
}
.featured__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 15px;
}
