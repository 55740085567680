.funzo {
  min-height: 80vh;
  max-width: 90vw;
  margin: 15vh auto 20px auto;
  .banner {
    display: flex;
    flex-wrap: wrap !important;
    .banner__left {
      flex: 0.5;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 20px;

      h2 {
        font-size: 2.5rem;
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 30px;
      }
      a {
        background-color: dodgerblue;
        max-width: 200px;
        padding: 10px;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        text-align: center;
      }
    }
    .banner__right {
      flex: 0.5;
      img {
        max-width: 100%;
        border-radius: 50px;
      }
    }
  }
  .benefits {
    max-width: 1000px;
    margin: 40px auto 20px auto;
    background-color: rgb(243, 240, 240);
    padding: 15px 2rem;
    border-radius: 20px;
    h3 {
      color: dodgerblue;
      margin-bottom: 15px;
      text-decoration: underline;
    }
    .section {
      margin-bottom: 20px;
      ul {
        list-style-type: square;
        padding-left: 30px;
      }
    }
  }
}
